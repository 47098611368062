import { useLocation, Outlet } from 'react-router-dom';
// @mui
import { Box, Link, Container, Typography, Stack } from '@mui/material';
//
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';

import '../../css/home.css';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();

  return (

    <div className="body">
      <Stack sx={{ minHeight: 1 }}>
        <MainHeader />
        
        <div className="main-container">
          <div className="secondary-container">
            <Outlet />
          </div>
        </div>

        <Box sx={{ flexGrow: 1 }} />

          <MainFooter />

      </Stack>
    </div>

    
  );
}
