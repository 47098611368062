import "simplebar/dist/simplebar.css";

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import { createRoot } from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

import { AuthProvider } from './contexts/FirebaseContext';
import { ConfigProvider } from './contexts/ConfigContext';
import { DashboardSessionProvider } from './contexts/DashboardSessionContext';
//
import App from './App';

// ----------------------------------------------------------------------

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <AuthProvider>
    <DashboardSessionProvider>
      <ConfigProvider>
        <HelmetProvider>
          <SettingsProvider>
            
              <CollapseDrawerProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </CollapseDrawerProvider>
            
          </SettingsProvider>
        </HelmetProvider>
      </ConfigProvider>
    </DashboardSessionProvider>
  </AuthProvider>
);
