
import { Box, Grid, Link, Divider, Container, Typography, Stack } from '@mui/material';

import "../../css/home.css";

export default function MainFooter() {
  return (
    <div className="footer">
      <hr className="landing-hr" />
      <Stack direction="column" spacing={0} justifyContent="center" alignItems="center">
        <div>Copyright © 2024 Enchanted Pixel LLC.</div>
        <Link href="/privacy">Privacy Policy</Link>
      </Stack>
    </div>
  );
}
