import { useLocation, Link } from 'react-router-dom';
import "../../css/home.css";

import SmallIcon from '../../assets/EnchantedLogoSmall.png';

export default function MainHeader() {

  const { pathname } = useLocation();

  return (
    <div className="nav-bar">
      <div className="container-fluid nav-container">
        <Link to="/">
          <img className="nav-logo" src={SmallIcon} alt="Enchanted Pixel Logo"/>
        </Link>
      </div>
    </div>
  );
}
