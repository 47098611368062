import PropTypes from 'prop-types';
import { useEffect, createContext, useContext, useState, useRef } from 'react';
import EventEmitter from 'events';
import {signal} from "@preact/signals-react";

const initialState = {
    lastEmailSearchResults: null,
    lastUserQueryListResults: null,
    lastUserQueryOption: { value: null },
    lastUserQueryPageSize: { value: 5 },
    lastNetworkRoomsResults: null
};

const DashboardSessionContext = createContext({
    initialState
});

DashboardSessionProvider.propTypes = {
    children: PropTypes.node,
};
  
function DashboardSessionProvider({ children }) {

    const lastEmailSearchResultsData = signal(null);

    const lastUserQueryListResults = signal(null);
    const lastUserQueryOption = { value: null };
    const lastUserQueryPageSize = { value: 5 };

    const lastNetworkRoomsResults = signal(null);

    return (

        <DashboardSessionContext.Provider
            value={{
                lastEmailSearchResultsData,
                lastUserQueryListResults,
                lastUserQueryOption,
                lastUserQueryPageSize,
                lastNetworkRoomsResults
            }}
        >
            
            {children}

        </DashboardSessionContext.Provider>
    );
}

export { DashboardSessionContext, DashboardSessionProvider };
