// components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import { 
  faHouse,
  faRankingStar,
  faUsers,
  faEnvelope,
  faBoltLightning,
  faNetworkWired
} from "@fortawesome/free-solid-svg-icons";
import { get } from "firebase/database";

import { PATH_DASHBOARD } from '../../../routes/paths';

library.add(faHouse, faRankingStar, faUsers, faEnvelope, faBoltLightning, faNetworkWired);

// ----------------------------------------------------------------------

const getIcon = (name) => <FontAwesomeIcon icon={name} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  
  dashboard: getIcon('house'),
  events: getIcon('bolt-lightning'),
  leaderboards: getIcon('ranking-star'),
  networkRooms: getIcon('network-wired'),
  mail: getIcon('envelope'),
  user: getIcon('users'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Enchanted Pixel',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.home, icon: ICONS.dashboard },
      { title: 'Events', path: PATH_DASHBOARD.events.root, icon: ICONS.events },
      { title: 'Leaderboards', path: PATH_DASHBOARD.leaderboards, icon: ICONS.leaderboards },
      { title: 'Rooms', path: PATH_DASHBOARD.networkRooms, icon: ICONS.networkRooms }
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      { title: 'Messages', path: PATH_DASHBOARD.messages, icon: ICONS.mail },
      { title: 'Users', path: PATH_DASHBOARD.users.root, icon: ICONS.user },
    ],
  },
];

export default navConfig;
